class Dot {
  constructor(x, y, radius) {
    this.x = x;
    this.y = y;
    this.radius = radius;
    this.initialRadius = radius;
    this.maxRadius = radius * 3;
    this.mouseProximity = 50;
  }

  update(mouse) {
    let distanceX = mouse.x - this.x;
    let distanceY = mouse.y - this.y;
    let distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

    if (distance < this.mouseProximity) {
      if (this.radius < this.maxRadius) {
        this.radius += 1;
      }
    } else if (this.radius > this.initialRadius) {
      this.radius -= 1;
    }
  }

  draw(ctx) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    //ctx.fillStyle = 'black';
    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)'; // 0.2 is the alpha value (transparency)
    ctx.fill();
  }
}

const canvas = document.querySelector('#dotMatrixCanvas');
const ctx = canvas.getContext('2d');

const mouse = {
  x: undefined,
  y: undefined,
};

canvas.width = window.innerWidth;
canvas.height = window.innerHeight;

const dotArray = [];

function init() {
  const dotSpacing = 40;

  for (let x = 0; x < canvas.width; x += dotSpacing) {
    for (let y = 0; y < canvas.height; y += dotSpacing) {
      const radius = 2;
      dotArray.push(new Dot(x, y, radius));
    }
  }
}

function animate() {
  requestAnimationFrame(animate);
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  dotArray.forEach((dot) => {
    dot.update(mouse);
    dot.draw(ctx);
  });
}

init();
animate();

window.addEventListener('mousemove', (event) => {
  mouse.x = event.x;
  mouse.y = event.y;
});

window.addEventListener('resize', () => {
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  init();
});
